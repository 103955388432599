import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
//import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

import secondCaseMockup from "../images/erinmockup1.png";
import secondCaseLogo from "../images/erinlogo.svg";
import feraName from "../images/mafer.svg";

import journey from "../images/erinpath.jpg";
import features2 from "../images/features2.svg";
import wire1 from "../images/erincharacter.svg";
import wire2 from "../images/erinscene.png";
import figma1 from "../images/scene2.png";
import game1 from "../images/game1.png";
import game2 from "../images/game2.png";
import game3 from "../images/game3.png";
import game4 from "../images/game4.png";
import game5 from "../images/game5.png";
import game6 from "../images/game6.png";
import game7 from "../images/game7.png";

function ErinPage() {
    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title=""
            />

            <section>
            <Link to="/">
                <div className="relative h-auto w-auto ...">
                    <div>
                        <img
                            alt="Case One"
                            className="md:w-64 object-cover "
                            src={feraName}
                        />
                        {/*   <h2 className=" inline-block p-0.5 px-4 mb-4 leading-tight text-2xl font-semibold tracking-wide text-gray-800 bg-green-200">
          Fernanda Ramírez
        </h2> */}
                    </div>
                    <div className="-m-2">
                        <h2 className="inline-block p-0 px-4 mt-4 mb-4 md:text-1xl font-light text-gray-800 text-sm">
                            Product Designer
        </h2>
                    </div>

                </div>
                </Link>
            </section>

            <section className="relative flex items-center justify-center">
                <div className="md:flex-shrink-0">

                    <img
                        alt="Case One"
                        className="h-16 md:w-40 w-24 pb-6"
                        src={secondCaseLogo}
                    />
                </div>

            </section>

            <section className="relative flex items-center justify-center">

                <p className="block mt-1 text-lg leading-tight text-gray-600 font-thin">Platformer Game Based on Personalities</p>
                
            </section>

            <section className="relative flex items-center justify-center">

<p className="block mt-1 text-lg leading-tight text-gray-600 font-light pt-6">- Best Graduation Project 2019 -</p>

</section>

            <section>
                <div className="md:flex-shrink-0 md:p-12 p-8 pt-16 md:pt-20">


                    <img
                        alt="Case One"
                        className="h-full w-full object-cover "
                        src={secondCaseMockup}
                    />
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-3 gap-2 ">
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Year</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center ">Duration</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Semester</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">2019</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center ">5 months</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">8th</div>
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-1 pt-20 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center ">The context</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2 ">Today, a great number of the most popular video games base their mechanics, dynamics and narratives on stereotypes. Erin is a video game that seeks to break this idea through experimentation and the use of personalities. For this purpose, decision making and the Myers-Briggs indicator, which yields 16 different personalities, are used as resources.
</p>
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-1 pt-12 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center pb-6">The process</div>
                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-2/5 w-4/5 bg-gray-300 mt-8">1. Identifying the problem</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">When looking for videogames there is a bast majority of them assigning attributes to their characters based on stereotypes. This stereotypical model of creation is seen in the most popular games where for example the main character is a white, male, stong man, the evil one is a black or brown man, and the supporting role is played by a girl.</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pb-6 md:px-20 px-2">In that sense, the player is left with no chance but to follow a linear narrative and act based on the characteristics the game assigns the player since the beggining without having the chance to reflect its own way of being.</p>

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-3/5 w-auto bg-gray-300 mb-8">2. Identifying the opportunity</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pb-2 md:px-20 px-2">What if considering that video games have become such an important phenomenon worldwide we tackle the stereotypical model of creation with a digital product design approach?</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pb-6 md:px-20 px-2">In a nutshell, I will try to apply concepts such as: challenging the user, creating and thinking about meaningful choices, keeping users hooked in engagement loops, and letting them master the product quickly to create a meaningful experience.</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pb-6 md:px-20 px-2">The one concept this game tries to explore the most and the one I will put more enfasis on is creating meaningful choices.</p>


                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-3/5 w-auto bg-gray-300 mb-8">3. Identifying the users</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pb-2 md:px-20 px-2">The game can be used by everyone +10 but is encouraged to be used by teenagers or young adults because there is a sense of awarness of the self.</p>


                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-16 grid-cols-1 md:p-8 p-2 ">

                        <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
                            <div className="md:flex">

                                <div className="relative p-8">


                                    <div className="relative flex items-center justify-center grid md:grid-cols-2  grid-cols-1">
                                        <div>
                                            <div className="uppercase tracking-wide text-base text-gray-900 font-bold mt-4">Everyone 10+</div>

                                            <p className="mt-2 font-light text-black">Want a game that has multiple narratives depending on the decisions they make.</p>
                                            <p className="mt-2 font-semibold text-semibold mt-4">Specific features:</p>
                                            <p className="mt-2 font-light text-black ">Entertaining.</p>
                                            <p className="mt-1 font-light text-black ">Has great mechanics.</p>

                                        </div>

                                        <div>
                                            <div className="uppercase tracking-wide text-base text-gray-900 font-bold mt-8">Teen +</div>

                                            <p className="mt-2 font-light text-black">Want a game that has multiple narratives depending on the decisions they make.</p>
                                            <p className="mt-2 font-semibold text-semibold mt-4">Specific features:</p>
                                            <p className="mt-2 font-light text-black ">Mirrors the self.</p>
                                            <p className="mt-1 font-light text-black ">Evolves with time.</p>

                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>


                    </div>

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-gray-300 mb-8 mt-8">4. Thinking about the walkthrough</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">The game is composed of different chapters and scenes. In each scene, the player will have to make a decision, that decision is related to one of the Myers-Briggs indicators.</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pb-6 md:px-20 px-2">The Myers-Briggs indicator is a self report that shows how people perceive the world and make decisions. It has four categories and one letter from each category produces a four letter test result. </p>



                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={journey}
                            />

                        </div>



                    </div>


                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-gray-300 mb-8 mt-8">5. Organizing the game</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={features2}
                            />

                        </div>



                    </div>


                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-gray-300 mb-8 mt-8">5. Creating the game</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light pt-6 md:px-20 px-2">With the map of the game in mind I satarted thinking of the type of game I wanted to create. I considered different possible games but I decided to go for a platformer one in 8bits in order to skip any kind of stereotypes that can be perceived at first sight.</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light  md:px-20 px-2">I also gave the character an androgynous name (Erin) and look.</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-2 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-auto w-auto object-cover rounded "
                                src={wire1}
                            />


                        </div>

                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={wire2}
                            />
                        </div>

                    </div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">And going from the sprites to Unity I made sure the mechanics of the game were learned with an in-game tutorial. I also made sure the game had the right level of difficulty and required the right level of skills in order for it not to be abandoned easily.</p>

                    <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                        <img
                            alt="Case One"
                            className="h-full w-full object-cover rounded "
                            src={figma1}
                        />
                    </div>




                    <div className="tracking-wide text-2xl text-black font-extrabold text-center md:py-6 py:8 ">The result</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">I finally came up with a fully functional game with different mini games and decisions that yield 16 different personalities at the end of the first chapter.</p>

                    <div className="relative flex items-center justify-center grid grid-cols-1 gap-2 ">
                        <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">
                            <div className="md:flex-shrink-0 md:p-4 p-2 pt-8 md:pt-8">
                                <img
                                    alt="Case One"
                                    className="h-full w-full object-cover rounded "
                                    src={game1}
                                />
                            </div>

                        </div>
                        <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center ">
                            <div className="md:flex-shrink-0 md:p-4 p-2 pt-2 md:pt-4">
                                <img
                                    alt="Case One"
                                    className="h-full w-full object-cover rounded "
                                    src={game2}
                                />
                            </div>

                        </div>
                        <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">
                            <div className="md:flex-shrink-0 md:p-4 p-2 pt-2 md:pt-4">
                                <img
                                    alt="Case One"
                                    className="h-full w-full object-cover rounded "
                                    src={game3}
                                />
                            </div>

                        </div>
                        <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">
                            <div className="md:flex-shrink-0 md:p-4 p-2 pt-2 md:pt-4">
                                <img
                                    alt="Case One"
                                    className="h-full w-full object-cover rounded "
                                    src={game4}
                                />
                            </div>

                        </div>
                        <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">
                            <div className="md:flex-shrink-0 md:p-4 p-2 pt-2 md:pt-4">
                                <img
                                    alt="Case One"
                                    className="h-full w-full object-cover rounded "
                                    src={game5}
                                />
                            </div>

                        </div>
                        <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">
                            <div className="md:flex-shrink-0 md:p-4 p-2 pt-2 md:pt-4">
                                <img
                                    alt="Case One"
                                    className="h-full w-full object-cover rounded "
                                    src={game6}
                                />
                            </div>

                        </div>
                        <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">
                            <div className="md:flex-shrink-0 md:p-4 p-2 pt-2 md:pt-4">
                                <img
                                    alt="Case One"
                                    className="h-full w-full object-cover rounded "
                                    src={game7}
                                />
                            </div>

                        </div>
                        

                    </div>

                    <div className="tracking-wide text-xl text-gray-700 font-bold text-center py-6">Thank you for reading!</div>

                </div>

            </section>

        </Layout>
    );
}

export default ErinPage;
